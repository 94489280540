<template>
  <section>
    <!-- first floor -->
    <b-row>
      <b-col
        md="6"
        lg="9"
      >
        <b-row>
          <b-col
            md="4"
            lg="3"
            class="ll-p"
          >
            <b-card
              class="ll-c ll-4"
              header="TKID"
              :title="jobBaseInfo.tkID"
            >
            </b-card>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="ll-p"
          >
            <b-card
              class="ll-c ll-4 ll-show"
              header="Status"
              :title="jobBaseInfo.latestOrderStatus"
            >
              <span v-if="showStatusTime">on {{ jobBaseInfo.latestOrderStatusTime }}</span>
            </b-card>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="ll-p"
          >
            <!-- <b-card
              class="ll-c ll-4"
              header="Service Party"
              :title="serviceParty"
            >
            </b-card> -->
            <b-card
              class="ll-c ll-4"
              header="Est. Delivery Date"
              title=""
            >
            </b-card>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="ll-p"
          >
            <!-- <b-card
              class="ll-c ll-4"
              header="Attempts"
              :title="attempts"
            >
            </b-card> -->
            <b-card
              class="ll-c ll-4"
              header="No. Of Attempts"
              title=""
            >
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <div class="ll-tagBox">
          <div v-for="(item, index) of tags" :key="index">
            <b-badge
              pill
              class="ll-badge"
              v-if="item === 'CUS_COMPLETE' || item === 'DELIVERED' || item === 'SELF_PICKUP'"
              :variant="`light-${col}`">
              <span v-if="item !== null">{{ item.replace('_', ' ') }}</span>
            </b-badge>
            <b-badge
              pill
              class="ll-badge"
              v-if="item === 'NO_SORT_CODE' || item === 'RESCHEDULED' || item === 'DELIVERY_DELAY' || item === 'LATE_ARRIVAL'"
              :variant="`light-${col1}`">
              <span v-if="item !== null">{{ item.replace(/_/g, ' ') }}</span>
            </b-badge>
            <b-badge
              pill
              class="ll-badge"
              v-if="item === 'REJECTED' || item === 'DAMAGED' || item === 'UNDELIVERABLE' || item === 'LOST' || item === 'DAMAGED' || item === 'SCRAPPED'"
              :variant="`light-${col2}`">
              <span v-if="item !== null">{{ item.replace('_', ' ') }}</span>
            </b-badge>
            <b-badge
              pill
              class="ll-badge"
              v-if="item === 'REMOTE_AREA' || item === 'RETURNED'"
              :variant="`light-${col3}`">
              <span v-if="item !== null">{{ item.replace('_', ' ') }}</span>
            </b-badge>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- second floor -->
    <b-row>
      <b-col
        md="6"
        lg="9"
        class="ll-p"
      >
      <b-card
        no-body
        class="card-browser-states ll-r ll-hei"
        >
          <b-card-header>
            <div class="ll-1">
              <b-card-title class="ll-title">Shipment status tracking</b-card-title>
              <b-form-input
                v-model="searchPID"
                placeholder="Search"
                type="search"
                class="ll-input"
              />
            </div>
          </b-card-header>
          <b-card-body class="ll-cb">
            <b-table :items="items" :fields="sTrackfields"
              :filter="searchPID" :borderless='false' :hover="true"
              :sticky-header="true"
              class="ll-tableHei"
            >
              <template #cell(statusType)="scope">
                <feather-icon
                  :icon="(scope.value==='NORMAL') ? icon1 : icon2"
                  size="16"
                  :class="(scope.value==='NORMAL') ? color1 : color2"
                />
              </template>
            </b-table>
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        md="6"
        lg="3"
        class="ll-p"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-hei">
          <b-card-header>
            <b-card-title>Destination Location</b-card-title>
            <b-button
              v-b-toggle.sidebar
              id="popover-reactive-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
            >
              <span>Edit</span>
            </b-button>
          </b-card-header>
          <b-card-body>
            <div class="ll-map" ref="map">
            </div>
            <div class="ll-box">
              <label class="ll-lab">Service Party</label>
              <span class="ll-span" v-if="imgData !== ''">
                <b-img
                  :src="imgData"
                  height="auto"
                  class="mr-1 ll-img"
                />
              </span>
              <span class="ll-span" v-else>N/A</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">Address</label>
              <span class="ll-span">{{ jobBaseInfo.updAddress }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">Remark</label>
              <span class="ll-span">{{ jobBaseInfo.updRemark }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">Sorting Code</label>
              <span class="ll-span">{{ jobBaseInfo.sortCode }}</span>
            </div>
            <div class="ll-box">
              <label class="ll-lab">SC Sorting Code</label>
              <span class="ll-span">{{ jobBaseInfo.scSortCode }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- third floor -->
    <b-row>
      <b-col
        lg="9"
        class="ll-p"
      >
        <b-card
          no-body
          class="card-browser-states ll-r ll-6 ll-h"
        >
          <b-card-header>
            <b-card-title>Charges</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-table :items="chargesList" :fields="chargeFields" :borderless='false' :hover="true"/>
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        lg="3"
        class="ll-p"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-6 ll-h">
          <b-card-header>
            <b-card-title>Job Details</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form-group
              label="KEC OrderID"
              label-for="orderId"
              class="ll-bform"
            >
              <b-form-input
                id="orderId1"
                v-model="jobBaseInfo.orderID"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Shipping Date"
              label-for="s"
              class="ll-bform"
            >
              <b-form-input
                v-model="jobBaseInfo.pkShippingDate"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Manifest No"
              label-for="orderId"
              class="ll-bform"
            >
              <b-form-input
                id="orderId2"
                v-model="jobBaseInfo.mfNo"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="MAWB No"
              label-for="mawbNo"
              class="ll-bform"
            >
              <b-form-input
                id="mawbNo"
                v-model="mawb"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Customer Order No"
              label-for="cusOrderNo"
              class="ll-bform"
            >
              <b-form-input
                id="cusOrderNo"
                v-model="customerOrderNumber"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="3rd Party Ref No"
              label-for="partyRefNo"
              class="ll-bform"
            >
              <b-form-input
                id="partyRefNo"
                v-model="refNo"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Number of Returns"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                id="numRtn"
                v-model="numberOfReturn"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="EAD"
              label-for="ead"
              class="ll-bform"
              v-if="false"
            >
              <b-form-input
                v-model="jobBaseInfo.ead"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Sorting Code"
              label-for="sortCode"
              class="ll-bform"
              v-if="false"
            >
              <b-form-input
                id="sortCode"
                v-model="jobBaseInfo.sortCode"
                readonly
              />
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card
          no-body
          class="card-browser-states ll-6"
          v-if="false">
          <b-card-header>
            <b-card-title>Routing</b-card-title>
            <b-button
              v-b-toggle.sidebar
              id="popover-reactive-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
            >
              <span>Edit</span>
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-form-group
              label="Sorting Code"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                v-model="refNo"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="SC Sorting Code"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                v-model="refNo"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Self-pick Location"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                v-model="refNo"
                readonly
              />
            </b-form-group>
            <p class="ll-pTit">Change Of Delivery</p>
            <b-form-group
              label="New Address"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                v-model="refNo"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Remark"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                v-model="refNo"
                readonly
              />
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        lg="12"
        class="ll-p"
      >
      <b-card
          no-body
          class="card-browser-states ll-r ll-6"
          v-if="false"
        >
          <b-card-header>
            <b-card-title>SLA Tracking</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-table :items="slaList"
              :striped="false"
              :bordered="false"
              :borderless='false'
              :hover="true"
              class="ll-th">
                <template #head(dropDown)>
                  <span>detail</span>
                </template>
                <template #cell(dropDown)="row">
                  <feather-icon
                    :icon="(row.detailsShowing) ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    size="16"
                    @click="row.toggleDetails"
                  />
                </template>
              <!-- <template #cell(slaId)="row">
                <div @click="row.toggleDetails">
                  {{ row.value }}
                </div>
              </template> -->
              <template #row-details="row">
                <div v-if="false"> {{row.item.slaId}} </div>
                <b-table :items="sList" class="ll-sth">
                  <template #cell(status)="scope">
                    <!-- <i class="fa fa-hourglass ll-icon"></i> -->
                    <feather-icon
                      :icon="(scope.value==='true') ? icon1 : icon2"
                      size="16"
                      class="ll-icon"
                    />
                  </template>
                </b-table>
              </template>
            </b-table>
          </b-card-body>
       </b-card>
      </b-col>
    </b-row>

    <!-- routeing sidebar -->
    <b-sidebar id="sidebar" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3 py-2 ll-py">
        <validation-observer ref="rules">
          <b-card-actions
            title="Update sort code"
            action-collapse
            :collapsed="collapsed"
            class="ll-cardAct"
          >
            <b-card-body>
              <b-form-group
                label="Select a delivery area"
                label-for="area"
              >
                <validation-provider
                  #default="{ errors }"
                  name="area"
                  rules="required"
                >
                  <v-select
                    id="area"
                    :options="areaList"
                    label="routeArea"
                    v-model="area"
                    placeholder="Please select area"
                    class="ll-select"
                    @input="getArea"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Select a district"
                label-for="district"
                v-if="isDis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="district"
                  rules="required"
                >
                  <v-select
                    id="district"
                    :options="districtList"
                    label="routeDistrict"
                    v-model="district"
                    placeholder="Please select district"
                    class="ll-select"
                    @input="getDistrict"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Select a routing code"
                label-for="routeCode"
                v-if="isRcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="routeCode"
                  rules="required"
                >
                  <v-select
                    id="routeCode"
                    :options="routeCodeList"
                    label="sortingCode"
                    v-model="editRouting.sortCode"
                    placeholder="Please select routing code"
                    class="ll-select"
                    @input="getRouteCode"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card-body>
          </b-card-actions>
          <b-card-actions
            title="Update consignee address"
            action-collapse
            :collapsed="collapsed"
            class="ll-cardAct"
          >
            <b-card-body>
              <b-form-group
                label="Input a new address"
                label-for="s"
              >
                <b-form-input v-model="editRouting.updAddress"/>
              </b-form-group>
              <b-form-group
                label="Input a remark(Optional)"
                label-for="s"
              >
                <b-form-input v-model="editRouting.updRemark"/>
              </b-form-group>
            </b-card-body>
          </b-card-actions>
          <div class="ll-btn">
            <b-button
              variant="secondary"
              class="mr-1"
              @click="onClear"
            >
              Clear
            </b-button>
            <b-button
              v-b-toggle.sidebar
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click.prevent="checkRouting"
            >
              Save
            </b-button>
          </div>
        </validation-observer>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, VBToggle,
  BCardHeader, BCardTitle, BCardBody, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    // flatPickr,
    BSidebar,
    // AppTimeline,
    // AppTimelineItem,
    vSelect,
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      icon1: 'CheckCircleIcon',
      icon2: 'AlertTriangleIcon',
      color1: 'icon-sussess',
      color2: 'icon-fail',
      orderId: '',
      searchPID: '',
      borderless: true,
      items1: [
        {
          status: 'true', timeUpdated: '2021-1-22 13:33', statusNo: '2500', description: 'Delivered', externalCode: 'KE/9D', source: 'KES', operator: 'SYSTEM', refNo: 'handoverNo: 29832983287',
        },
        {
          status: 'true', timeUpdated: '2021-1-22 13:33', statusNo: '2500', description: 'Delivered', externalCode: 'KE/9D', source: 'KES', operator: 'SYSTEM', refNo: 'handoverNo: 29832983287',
        },
        {
          status: 'false', timeUpdated: '2021-1-22 13:33', statusNo: '2500', description: 'Delivered', externalCode: 'KE/9D', source: 'KES', operator: 'SYSTEM', refNo: 'handoverNo: 29832983287',
        },
      ],
      items: [],
      sTrackfields: [
        { key: 'statusType', label: '' },
        // { key: 'updatedAt', label: 'Time Updated' },
        { key: 'statusCode', label: 'StatusNo' },
        { key: 'statusDesc', label: 'Description' },
        { key: 'eventTime', label: 'Event time' },
        { key: 'originStatusCode', label: 'External Code' },
        { key: 'updateSource', label: 'Source' },
        { key: 'operator', label: 'Operator' },
        { key: 'refTkID', label: 'Ref No' },
      ],
      slaList: [
        {
          slaId: 'SLA1111', salName: 'Delive SLA', clockstart: '2021-1-22 13:33', clockstop: '2021-1-22 13:33', slaDue: '', result: '', dropDown: '',
        },
        {
          slaId: 'SLA1111', salName: 'Delive SLA', clockstart: '2021-1-22 13:33', clockstop: '2021-1-22 13:33', slaDue: '', result: '', dropDown: '',
        },
      ],
      sList: [
        {
          status: 'true', statusNo: '1100', description: 'Ordre received', timeUpdated: '2020-09-10 11:34:39',
        },
        {
          status: 'true', statusNo: 'PROD', description: 'Ordre received', timeUpdated: '2020-09-10 11:34:39',
        },
        {
          status: 'false', statusNo: 'DIP', description: 'Ordre received', timeUpdated: '2020-09-10 11:34:39',
        },
      ],
      chargesList: [],
      chargeFields: [
        { key: 'feeType', label: 'Charge Code' },
        { key: 'amount', label: 'Amount' },
        { key: 'updatedAt', label: 'Date Charged' },
        { key: 'operator', label: 'Charged By' },
        { key: 'invoiceNo', label: 'Invoice No' },
        { key: 'invoiceType', label: 'Invoice Type' },
      ],
      jobTrack: {
        page: '1',
        size: '20',
        startEventTime: null,
        endEventTime: null,
        statusCode: null,
        orderBy: 'id',
        orderMole: '0',
        tkIDs: '',
      },
      condition: {
        page: '1',
        size: '10',
        trackingIDs: [],
        orderBy: 'id',
        orderMole: 1,
      },
      jobBaseInfo: {
        tkID: '',
        latestOrderStatus: '',
        orderID: '',
        mfNo: '',
        sortCode: '',
        attempts: '',
        serviceParty: '',
        mawb: '',
        numberOfReturn: '',
        customerOrderNumber: '',
        pkShippingDate: '',
        ead: '',
        scSortCode: '',
        latitude: null,
        longitude: null,
        latestOrderStatusTime: '',
        updAddress: '',
        updRemark: '',
      },
      tags: [],
      attempts: '',
      serviceParty: '',
      mawb: '',
      numberOfReturn: '',
      customerOrderNumber: '',
      refNo: '',
      condition1: {
        page: '1',
        size: '15',
        orderBy: 'id',
        status: null,
        invoiceType: null,
        beginDate: null,
        endDate: null,
        refNo: null,
        invoiceNo: null,
      },
      list: [],
      col: 'info',
      col1: 'primary',
      col2: 'danger',
      col3: 'secondary',
      longitude: 114.132294, // 经度
      latitude: 22.360371, // 维度
      showStatusTime: false,
      areaList: [],
      area: '',
      districtList: [],
      district: '',
      routeCodeList: [],
      editRouting: {
        id: '',
        sortCode: '',
        updRemark: '',
        updAddress: '',
      },
      required,
      sParty: '',
      imgData: '',
      collapsed: true,
      isDis: false,
      isRcode: false,
    }
  },
  created() {},
  mounted() {
    this.getDetails()
    this.getJobDetails()
    this.statusTrack()
    this.queryCharge()
    this.queryArea()
  },
  methods: {
    initMaps() {
      if (this.jobBaseInfo.latitude === 0 || this.jobBaseInfo.longitude === 0) {
        this.maps = new google.maps.Map(this.$refs.map, {
          zoom: 8,
          // 设置地图中心点
          center: { lat: this.latitude, lng: this.longitude },
          // 关闭默认控件集,设置地图的disableDefaultUI的属性为true
          disableDefaultUI: true,
          // 启用缩放和平移
          gestureHandling: 'greedy',
          language: 'zh_cn',
        })
        this.marker = new google.maps.Marker({
          position: { lat: this.latitude, lng: this.longitude },
          map: this.maps,
        })
      } else {
        this.maps = new google.maps.Map(this.$refs.map, {
          zoom: 17,
          // 设置地图中心点
          center: { lat: this.jobBaseInfo.latitude, lng: this.jobBaseInfo.longitude },
          // 关闭默认控件集,设置地图的disableDefaultUI的属性为true
          disableDefaultUI: true,
          // 启用缩放和平移
          gestureHandling: 'greedy',
          language: 'zh_cn',
        })
        this.marker = new google.maps.Marker({
          position: { lat: this.jobBaseInfo.latitude, lng: this.jobBaseInfo.longitude },
          map: this.maps,
        })
      }
    },
    // job base info
    getJobDetails() {
      const tkid = this.$route.params.id
      this.condition.trackingIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobkes/search', this.condition).then(res => {
        // console.log('ss', res.data.data)
        const info = res.data.data.content[0]
        this.jobBaseInfo = info
        this.tags = info.tag
        this.editRouting.id = info.id
        this.sParty = info.serviceParty
        this.jobBaseInfo.latitude = Number(info.latitude)
        this.jobBaseInfo.longitude = Number(info.longitude)
        this.initMaps()
        if (this.sParty !== null) {
          this.querySParty()
        }
        switch (info.latestOrderStatus) {
          case 'REC':
            this.jobBaseInfo.latestOrderStatus = 'Order received'
            break
          case 'TRA':
            this.jobBaseInfo.latestOrderStatus = 'In transit'
            break
          case 'PROD':
            this.jobBaseInfo.latestOrderStatus = 'On Warehouse'
            break
          case 'DIP':
            this.jobBaseInfo.latestOrderStatus = 'Delivery in progress'
            break
          default:
            this.jobBaseInfo.latestOrderStatus = 'Completed'
            this.showStatusTime = true
            break
        }
      }).catch(error => console.log(error))
    },
    getDetails() {
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: this.$route.params.orderId } }).then(res => {
        const info = res.data.data
        // console.log('res1', info)
        if (info.attempts === null) {
          this.attempts = info.attempts
        } else {
          this.attempts = info.attempts.toString()
        }
        this.serviceParty = info.serviceParty
        this.mawb = info.mawb
        this.numberOfReturn = info.numberOfReturn
        this.customerOrderNumber = info.customerOrderNumber
        this.refNo = info.refNo
      }).catch(error => console.log(error))
    },
    // status tracking
    statusTrack() {
      const tkID = this.$route.params.id
      this.jobTrack.tkIDs = tkID.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobTrack/search', this.jobTrack).then(res => {
        // console.log(res.data.data.content)
        this.items = res.data.data.content
      })
    },
    queryCharge() {
      this.condition1.refNo = this.$route.params.id
      this.$http.post('invoiceDetail/search', this.condition1).then(res => {
        // console.log(res.data.data)
        this.chargesList = res.data.data.content
      })
    },
    // routing 弹框
    queryArea() {
      this.$http.get('routeList/getRouteAreaList').then(res => {
        this.areaList = res.data.data
      })
    },
    getArea(s) {
      this.area = s.routeArea
      if (this.area !== null) {
        this.queryDistrict()
        this.isDis = true
      }
    },
    queryDistrict() {
      const data = {}
      data.routeArea = this.area
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        this.districtList = res.data.data
      })
    },
    getDistrict(s) {
      this.district = s.routeDistrict
      if (this.district !== null) {
        this.queryRouteCode()
        this.isRcode = true
      }
    },
    queryRouteCode() {
      const data = {}
      data.routeArea = this.area
      data.routeDistrict = this.district
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        this.routeCodeList = res.data.data
      })
    },
    getRouteCode(s) {
      this.editRouting.sortCode = s.sortingCode
    },
    checkRouting() {
      return new Promise((resolve, reject) => {
        this.$refs.rules.validate().then(success => {
          if (success) {
            this.saveRouting()
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please check the data',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            reject()
          }
        })
      })
    },
    onClear() {
      this.editRouting.updRemark = ''
      this.editRouting.updAddress = ''
      this.editRouting.sortCode = ''
      this.area = ''
      this.district = ''
    },
    saveRouting() {
      this.$http.put('jobkes/editRoutingCode', this.editRouting).then(res =>{
        if (res.data.code === '200') {
          this.getJobDetails()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    querySParty() {
      this.$http.get('admin/settings/organization/getByName', { params: { name: this.sParty}}).then(res=> {
        this.imgData = res.data.data
      })
    },
  },
}
</script>
<style scoped>
.ll-map{
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}
.ll-hei{
  height: 450px;
  margin-bottom: 0px;
  overflow-y: auto;
}
.ll-0{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.mb-0{
  font-size: 12px;
}
.ll-1{
  display: flex;
  width: 100%;
}
.ll-title{
  width: 70%;
  margin: auto;
}
.ll-input{
  width: 30%;
}
.ll-p{
  padding: 0.5rem;
}
.ll-c{
  margin-bottom: 0;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
/* .ll-c .card-body{
  padding: 0.5rem;
  text-align: center;
} */
.ll-c .card-body .card-title{
  font-weight: normal;
  /* font-size: 14px; */
}
.d-block{
  font-size: 14px;
}
/* .ll-h .col{
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  font-weight: bold;
} */
.ll-btn{
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
}
/* .ll-btn .btn-danger, .ll-btn .btn-primary{
  width: 30%;
} */
.ll-3{
  margin-top: 10px;
}
.ll-3 div{
  padding: 0;
}
.g-label{
  justify-content: space-between !important;
}
.ll-4{
  height: 80px;
  background-color: unset;
  box-shadow: unset;
}
.ll-4 .card-header{
  font-weight: normal;
  font-size: 12px;
}
/* .ll-4 .card-body{
  text-align: center;
} */
.ll-4 .card-title{
  font-weight: 500 !important;
  font-size: 16px;
}
.ll-bform{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-6{
  height: 340px;
  margin-bottom: 0px;
  overflow-y: auto;
}
.ll-trim{
  position: relative;
  left: 40px;
}
.ll-trim1{
  position: relative;
  left: 5px;
}
.ll-badge{
  padding: 5px;
  margin: 5px;
  font-size: 14px;
}
.ll-cb{
  margin: 1.5rem;
  margin-top: 0;
}
.ll-icon{
  color: red;
  float: right;
}
.icon-sussess{
  color: #28c76f;
}
.icon-fail{
  color: #ff9f43;
}
.ll-form {
  display: flex;
  justify-content: space-between;
}
.ll-tagBox{
  display: flex;
  height: 80px;
  align-items: flex-end;
  /* padding: 2rem 0.5rem; */
}
.ll-pTit{
  font-weight: bold;
  margin-top: 20px;
}
.ll-tableHei{
  height: 294px;
}
.ll-r{
  margin-bottom: 0px;
}
.ll-h{
  height: 370px;
}
.ll-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 6px;
}
.ll-box .ll-lab{
  font-weight: 500;
}
.ll-show .card-body{
  display: flex;
}
.ll-show .card-body span{
  padding-top: 3px;
  padding-left: 5px;
}
.ll-py{
  padding-top: 0 !important;
}
</style>
<style>
.ll-cardAct>.card{
  box-shadow: unset;
}
.ll-cardAct .card-header, .ll-cardAct .card-body{
  padding: 0;
  margin-bottom: 10px;
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 5px;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 28%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
/* [dir] label {
  width: 40%;
} */
.ll-img{
  width: 80px;
  height: 28px;
}
</style>
