<template>
  <section>
    <b-row>
      <b-col
        md="6"
        lg="6"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-6">
          <b-card-header>
            <b-card-title>Ship from</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="6" lg="4">
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="baseInfo.fromName"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="Address"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="baseInfo.fromAddress"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="City"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="baseInfo.fromCity"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" lg="4">
                <b-form-group
                  label="State"
                  label-for="state"
                >
                  <b-form-input
                    id="state"
                    v-model="baseInfo.fromState"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="ZIP"
                  label-for="zip"
                >
                  <b-form-input
                    id="zip"
                    v-model="baseInfo.fromZip"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <b-form-input
                    id="country"
                    v-model="baseInfo.fromCountry"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="6"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-6">
          <b-card-header>
            <b-card-title>Ship to</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="6" lg="4">
                <b-form-group
                  label="Name"
                  label-for="name1"
                >
                  <b-form-input
                    id="name1"
                    v-model="baseInfo.toName"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="Address"
                  label-for="address1"
                >
                  <b-form-input
                    id="address1"
                    v-model="baseInfo.toAddress"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="City"
                  label-for="city1"
                >
                  <b-form-input
                    id="city1"
                    v-model="baseInfo.toCity"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" lg="4">
                <b-form-group
                  label="State"
                  label-for="state1"
                >
                  <b-form-input
                    id="state1"
                    v-model="baseInfo.toState"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="ZIP"
                  label-for="zip1"
                >
                  <b-form-input
                    id="zip1"
                    v-model="baseInfo.toZip"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="4">
                <b-form-group
                  label="Country"
                  label-for="country1"
                >
                  <b-form-input
                    id="country1"
                    v-model="baseInfo.toCountry"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        lg="12"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-6">
          <b-card-header>
            <b-card-title>Consignee</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="6" lg="3">
                <b-form-group
                  label="Name"
                  label-for="name2"
                >
                  <b-form-input
                    id="name2"
                    v-model="baseInfo.cname"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="3">
                <b-form-group
                  label="Address"
                  label-for="address2"
                >
                  <b-form-input
                    id="address2"
                    v-model="baseInfo.caddress"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="3">
                <b-form-group
                  label="Distinct"
                  label-for="distinct"
                >
                  <b-form-input
                    id="distinct"
                    v-model="baseInfo.cdistinct"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="3">
                <b-form-group
                  label="City"
                  label-for="city2"
                >
                  <b-form-input
                    id="city2"
                    v-model="baseInfo.ccity"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" lg="3">
                <b-form-group
                  label="ZIP"
                  label-for="zip3"
                >
                  <b-form-input
                    id="zip3"
                    v-model="baseInfo.czip"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="3">
                <b-form-group
                  label="Country"
                  label-for="country3"
                >
                  <b-form-input
                    id="country3"
                    v-model="baseInfo.ccountry"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="3">
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="baseInfo.cphone"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" lg="3">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="baseInfo.cemail"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        lg="12"
        class="ll-p2"
      >
      <b-card
          no-body
          class="card-browser-states ll-r ll-6"
        >
          <b-card-header>
            <b-card-title>Package</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-table :items="packageList"
              :fields="fields"
              :striped="false"
              :bordered="false"
              :hover="true"
              responsive
              class="ll-t">
                <template #head(dropDown)>
                  <span>detail</span>
                </template>
                <template #cell(title)="row">
                  <div class="pTitle">{{ row.value }}</div>
                </template>
                <template #cell(dropDown)="row">
                  <!-- <feather-icon
                    :icon="(row.detailsShowing) ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    size="16"
                    @click="row.toggleDetails"
                  /> -->
                  <span @click="getPackageProducts()">
                    <feather-icon
                    :icon="(row.detailsShowing) ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    size="16"
                    @click="row.toggleDetails"
                  />
                  </span>
                </template>
              <template #row-details="row">
                <input v-show="false" :value="row.item.orderId" ref="orderId"/>
                <b-table :items="pList" :fields="fieldsP">
                  <template #cell(title)="row">
                    <div class="pTitle">{{ row.value }}</div>
                  </template>
                </b-table>
              </template>
            </b-table>
          </b-card-body>
       </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BTable,
  BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
  },
  data() {
    return {
      packageList: [],
      fields: [
        { key: 'title', label: 'Title' },
        { key: 'value', label: 'value' },
        { key: 'length', label: 'length' },
        { key: 'width', label: 'width' },
        { key: 'height', label: 'height' },
        { key: 'itemqty', label: 'itemQty' },
        { key: 'dimWeight', label: 'dimWeight' },
        { key: 'actWeight', label: 'actWeight' },
        { key: 'dropDown', label: 'dropDown' },
      ],
      pList: [],
      fieldsP: [
        { key: 'productId', label: 'productId' },
        { key: 'amazonId', label: 'amazonId' },
        { key: 'title', label: 'title' },
        { key: 'quantity', label: 'quantity' },
        { key: 'unitPrice', label: 'unitPrice' },
        { key: 'currency', label: 'currency' },
        { key: 'hsCode', label: 'hsCode' },
        { key: 'customDesc', label: 'customDesc' },
        { key: 'origin', label: 'origin' },
      ],
      baseInfo: {
        caddress: '',
        ccity: '',
        ccountry: '',
        cdistinct: '',
        cemail: '',
        cname: '',
        cphone: '',
        czip: '',
        fromAddress: '',
        formCity: '',
        fromCountry: '',
        fromName: '',
        fromState: '',
        fromZip: '',
        toAddress: '',
        toCity: '',
        toCountry: '',
        toName: '',
        toState: '',
        toZip: '',
      },
      orderId: '',
    }
  },
  created() {},
  mounted() {
    this.getJobDetails()
  },
  methods: {
    getJobDetails() {
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: this.$route.params.orderId } }).then(res => {
        const info = res.data.data
        // console.log('res1', info)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          this.baseInfo = info
          this.packageList = info.packages
        }
      })
    },
    getPackageProducts() {
      // console.log(this.$refs.orderId)
      this.orderId = this.$refs.orderId.value
      this.$http.get('/jobkes/getPackageProducts', { params: { orderId: this.orderId } }).then(res => {
        const info = res.data.data
        // console.log('res', info)
        this.pList = info
      })
    },
  },
}
</script>
<style scoped>
.ll-0{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.mb-0{
  font-size: 12px;
}
.ll-1{
  display: flex;
  width: 100%;
}
.ll-title{
  width: 70%;
  margin: auto;
}
.ll-input{
  width: 30%;
}
.ll-p2{
  padding: 0.5rem;
}
.ll-c{
  margin-bottom: 0;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
/* .ll-c .card-body{
  padding: 0.5rem;
  text-align: center;
} */
.ll-c .card-body .card-title{
  font-weight: normal;
  font-size: 14px;
}
.d-block{
  font-size: 14px;
}
.ll-h .col{
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  font-weight: bold;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
}
.ll-btn .btn-danger, .ll-btn .btn-primary{
  width: 30%;
}
.ll-3{
  margin-top: 10px;
}
.g-label{
  justify-content: space-between !important;
}
.ll-4{
  height: 110px;
}
.ll-bform{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ll-6{
  min-height: 200px;
  margin-bottom: 0px;
}
.ll-trim{
  position: relative;
  left: 40px;
}
.ll-trim1{
  position: relative;
  left: 5px;
}
.ll-badge{
  border-radius: 0;
  padding: 10px;
}
/* .pTitle{
  width: 300px;
} */
/* .ll-t{
  height: 500px;
} */
</style>
<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 0.72rem;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 28%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
</style>
