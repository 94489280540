<template>
  <div>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          Amazon Order
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'amazon-job' }" >
          Order
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'amazon-job' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
    </b-button>
    <b-tabs
      pills
    >
      <!-- Tab: Job -->
      <b-tab active>
        <template #title>
          <span class="d-none d-sm-inline">Order</span>
        </template>
        <detail-job></detail-job>
      </b-tab>

      <!-- Tab: Package -->
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">Package</span>
        </template>
        <detail-package></detail-package>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab, BTabs, BButton, BBreadcrumb, BBreadcrumbItem, BImg,
} from 'bootstrap-vue'
import detailJob from './detailJob.vue'
import detailPackage from './detailPackage.vue'

export default {
  components: {
    BTab,
    BTabs,
    detailJob,
    detailPackage,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
  },
  data() {
    return {
    }
  },
  computed: {},
  created() {},
  methods: {},
}
</script>

<style scoped>
.ll-cancel{
  text-align: center;
}
</style>
<style>
[dir] .nav-pills .nav-link.active{
  font-weight: bold;
  /* background-color: unset;
  color: #5e5873;
  box-shadow: none;
  border: none;
  border-bottom: 3px solid #ed6d01;
  border-radius: 0px; */
}
</style>
